import { StyledSliderArrow } from './styles';

export interface SliderArrowProps {
  direction?: 'right' | 'left';
  onClick: () => void;
}

const SliderArrow = ({ direction = 'left', onClick }: SliderArrowProps) => {
  return (
    <StyledSliderArrow
      aria-label={`Arrow ${direction}`}
      onClick={onClick}
      direction={direction}
    >
      <span />
    </StyledSliderArrow>
  );
};

export default SliderArrow;
