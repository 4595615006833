import Slider from 'react-slick';
import { StoriesSection, StoriesSlider } from './styles';
import SliderSection from '@/scenes/common/SliderSection';
import Button from '@/components/UI/Button';
import { FC, useEffect, useRef, useState } from 'react';
import useMediaQuery from '@/hooks/useMediaQuery';
import { breakpointsBase } from '@/styles/breakpoints';
import { StoryCard } from '@/components/Story';
import { inject, observer } from 'mobx-react';
import { IStoriesStore } from '@/stores/Stories';
import { values } from 'mobx';
import { gsap, Power4 } from 'gsap';

interface StoriesProps {
  storiesStore: IStoriesStore;
  title: string;
}

const Stories: FC<any> = ({ storiesStore, title }: StoriesProps) => {
  const [loaded, setLoaded] = useState(false);
  const sliderWrapperRef = useRef(null);

  const isLG = useMediaQuery(breakpointsBase.LG);

  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(1);

  const stories = values(storiesStore.items).slice(0, 6);

  useEffect(() => {
    setLoaded(true);

    if (isLG) {
      setCurrentSlide(2);
    }
  }, []);

  useEffect(() => {
    if (!loaded) return;

    const tl = gsap.timeline({
      scrollTrigger: {
        // @ts-ignore
        trigger: sliderWrapperRef.current,
        start: 'top bottom',
      },
    });

    tl.fromTo(
      sliderWrapperRef.current,
      {
        transition: 'none',
        transformOrigin: '0% 0%',
        xPercent: 100,
      },
      {
        duration: 2.5,
        xPercent: 0,
        ease: Power4.easeInOut,
      },
    ).fromTo(
      '.stories-slider .slick-active',
      {
        transition: 'none',
        transformOrigin: '100% 50%',
        scaleX: 0.8,
        scaleY: 0.9,
        xPercent: 100,
        opacity: 0,
      },
      {
        duration: 2,
        transformOrigin: '100% 50%',
        scaleY: 1,
        scaleX: 1,
        delay: 0.2,
        xPercent: 0,
        opacity: 1,
        stagger: 0.35,
        ease: Power4.easeInOut,
      },
      0,
    );

    return () => {
      tl.kill();
    };
  }, [loaded]);

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    swipe: false,
    afterChange: (current) => {
      if (current === 0) {
        if (isLG) {
          return setCurrentSlide(2);
        }
        return setCurrentSlide(1);
      }

      if (isLG && current <= stories.length - 2) {
        return setCurrentSlide(current + 2);
      }

      if (!isLG && current <= stories.length - 1) {
        return setCurrentSlide(current + 1);
      }

      if (isLG && current > stories.length - 2) {
        return setCurrentSlide(current - (stories.length - 2));
      }
      if (!isLG && current > stories.length - 1) {
        return setCurrentSlide(current - (stories.length - 1));
      }
    },
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          swipe: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  return (
    <StoriesSection>
      <div className="container">
        <SliderSection
          rtl
          title={title}
          counter={{ current: currentSlide, total: stories.length }}
          action={
            <Button colored as="a" href="/stories">
              Explore
            </Button>
          }
          onPrevSlide={prevSlide}
          onNextSlide={nextSlide}
        >
          <div className="slider-cutter stories-slider" ref={sliderWrapperRef}>
            <StoriesSlider>
              <Slider ref={sliderRef} {...sliderSettings}>
                {stories.map((story) => (
                  // @ts-ignore
                  <StoryCard key={story.slug} story={story} />
                ))}
              </Slider>
            </StoriesSlider>
          </div>
        </SliderSection>
      </div>
    </StoriesSection>
  );
};

export default inject('storiesStore')(observer(Stories));
