import Image from 'next/image';
import Slider from 'react-slick';
import { gsap, Power4 } from 'gsap';
import {
  Bio,
  Info,
  Member,
  MemberImage,
  Name,
  Position,
  Skill,
  SkillLabel,
  Skills,
  SkillScale,
  SliderArrows,
  TeamSection,
  TeamSlider,
  Thumbnail,
  ThumbnailsSlider,
  Title,
  ToggleInfoButton,
} from '@/scenes/Home/Team/styles';
import SliderArrow from '@/components/Slider/Arrow';
import { useEffect, useRef, useState } from 'react';
import useMediaQuery from '@/hooks/useMediaQuery';
import { breakpointsBase } from '@/styles/breakpoints';
import { SplitText } from '@/utils/gsap/SplitText';
import uniq from 'lodash.uniq';

interface TeamMember {
  name: string;
  position: string;
  bio: string;
  thumbnail: string;
  image: string;
  skills: {
    label: string;
    score: number;
  }[];
}

interface Props {
  team: {
    name: string;
    bio: string;
    image: string;
    position: string;
    skill: {
      grade: number;
      name: string;
    }[];
  }[];
}

const Team = ({ team = [] }: Props) => {
  const teamMembers: TeamMember[] = team.map((item) => ({
    name: item.name,
    position: item.position,
    bio: item.bio,
    thumbnail: `/team/thumbnails/${item.image}.webp`,
    image: `/team/images/${item.image}.svg`,
    skills: item.skill.map((s) => ({ score: s.grade, label: s.name })),
  }));

  const sliderRef = useRef(null);
  const thumbnailsSliderRef = useRef(null);
  const teamMemberRef = useRef(null);

  const [loaded, setLoaded] = useState(false);
  const [activeMember, setActiveMember] = useState(0);
  const [activeInfo, setActiveInfo] = useState(false);

  const animatedTextItems = useRef([]);

  const fadeItemsRef = useRef([]);

  const isMD = useMediaQuery(breakpointsBase.MD);

  const sliderSettings = {
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    arrows: false,
    fade: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          swipe: true,
        },
      },
    ],
    afterChange: (current) => {
      gsap.set('.active-skill-score', {
        backgroundColor: '#A9A9A9',
      });
    },
    beforeChange: (c, n) => {
      setActiveMember(n);
    },
    asNavFor: thumbnailsSliderRef.current,
  };

  const thumbnailSliderSettings = {
    swipe: false,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    // centerMode: true,
    asNavFor: sliderRef.current,
  };

  useEffect(() => {
    if (!isMD || !loaded) return;

    const skills = document.querySelectorAll(`.skill-${activeMember}`);

    skills.forEach((skill) => {
      gsap.to(skill.querySelectorAll('.active-skill-score'), {
        duration: 0.2,
        backgroundColor: '#E85143',
        stagger: 0.2,
        delay: 0.2,
      });
    });
  }, [activeMember]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const onPrev = () => {
    // if (activeMember === 0) return;

    sliderRef.current.slickPrev();
    setActiveInfo(false);
    gsap.set('.active-skill-score', {
      backgroundColor: '#A9A9A9',
    });
  };

  const onNext = () => {
    // if (activeMember === teamMembers.length - 1) return;

    sliderRef.current.slickNext();
    setActiveInfo(false);
    gsap.set('.active-skill-score', {
      backgroundColor: '#A9A9A9',
    });
  };

  const toggleInfo = () => {
    setActiveInfo(!activeInfo);

    const skills = document.querySelectorAll(`.skill-${activeMember}`);

    skills.forEach((skill) => {
      gsap.to(skill.querySelectorAll('.active-skill-score'), {
        duration: 0.2,
        backgroundColor: '#E85143',
        stagger: 0.2,
        delay: 0.2,
      });
    });
  };

  useEffect(() => {
    if (!loaded) return;

    const tl = gsap.timeline();

    tl.to(teamMemberRef.current, {
      scrollTrigger: {
        trigger: teamMemberRef.current,
        start: '-200px top',
        onEnter: () => {
          const skills = document.querySelectorAll(`.skill-${activeMember}`);

          skills.forEach((skill) => {
            gsap.to(skill.querySelectorAll('.active-skill-score'), {
              duration: 0.2,
              backgroundColor: '#E85143',
              stagger: 0.2,
              delay: 0.2,
            });
          });
        },
      },
    });

    setTimeout(() => {
      uniq(animatedTextItems.current.filter((i) => i)).forEach((element) => {
        const childSplit = new SplitText(element, {
          type: 'lines',
          linesClass: 'animatedLineChild',
        });
        const parentSplit = new SplitText(element, {
          type: 'lines',
          linesClass: 'animatedLineParent',
        });

        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: 'top 92%',
          },
        });
        tl.fromTo(
          childSplit.lines,
          {
            transition: 'none',
            transformOrigin: '0% 0%',
            scaleY: 1,
            yPercent: 100,
          },
          {
            duration: 0.75,
            transformOrigin: '0% 0%',
            delay: 0.2,
            scaleY: 1,
            opacity: 1,
            y: 0,
            yPercent: 0,
            stagger: 0.075,
            ease: Power4.easeInOut,
            onComplete: () => {
              // @ts-ignore
              // childSplit.revert();
            },
          },
        ).set(parentSplit.lines, { overflow: 'visible' }, 0.2);
      });

      uniq(fadeItemsRef.current.filter((i) => i)).forEach((element) => {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: 'top 92%',
            // markers: true,
          },
        });
        tl.fromTo(
          element,
          {
            transition: 'none',
            transformOrigin: '0% 0%',
            scaleY: 1,
            opacity: 0,
            yPercent: 5,
          },
          {
            duration: 0.75,
            transformOrigin: '0% 0%',
            delay: 0.2,
            scaleY: 1,
            opacity: 1,
            y: 0,
            yPercent: 0,
            stagger: 0.075,
            ease: Power4.easeInOut,
          },
        );
      });
    }, 1500);

    return () => {
      tl.kill();
    };
  }, [loaded]);

  return (
    <TeamSection>
      <div className="container">
        <Title ref={(r) => animatedTextItems.current.push(r)}>The team</Title>
        <TeamSlider ref={teamMemberRef}>
          <Slider ref={sliderRef} {...sliderSettings}>
            {teamMembers.map((member, i) => (
              <div key={member.name}>
                <Member>
                  <Name ref={(r) => animatedTextItems.current.push(r)}>
                    {member.name}
                  </Name>
                  <Position
                    className="text-super-small"
                    ref={(r) => animatedTextItems.current.push(r)}
                  >
                    {member.position}
                  </Position>
                  <Info active={activeInfo}>
                    <div className="wrapper">
                      <Bio ref={(r) => animatedTextItems.current.push(r)}>
                        {member.bio}
                      </Bio>
                      <Skills ref={(r) => animatedTextItems.current.push(r)}>
                        {member.skills.map((skill) => (
                          <Skill key={skill.label}>
                            <SkillLabel>{skill.label}</SkillLabel>
                            <SkillScale className={`skill skill-${i}`}>
                              <span
                                className={
                                  skill.score > 0 ? 'active-skill-score' : ''
                                }
                              ></span>
                              <span
                                className={
                                  skill.score > 1 ? 'active-skill-score' : ''
                                }
                              ></span>
                              <span
                                className={
                                  skill.score > 2 ? 'active-skill-score' : ''
                                }
                              ></span>
                              <span
                                className={
                                  skill.score > 3 ? 'active-skill-score' : ''
                                }
                              ></span>
                              <span
                                className={
                                  skill.score > 4 ? 'active-skill-score' : ''
                                }
                              ></span>
                            </SkillScale>
                          </Skill>
                        ))}
                      </Skills>
                    </div>
                    <ToggleInfoButton onClick={toggleInfo}>
                      show {activeInfo ? 'less' : 'more'}...
                    </ToggleInfoButton>
                  </Info>
                  <MemberImage ref={(r) => fadeItemsRef.current.push(r)}>
                    <Image
                      width={430}
                      height={500}
                      src={member.image}
                      alt={member.name}
                    />
                  </MemberImage>
                </Member>
              </div>
            ))}
          </Slider>
          <SliderArrows>
            <SliderArrow onClick={onPrev} />
            <SliderArrow onClick={onNext} direction="right" />
          </SliderArrows>
        </TeamSlider>
        <ThumbnailsSlider ref={(r) => fadeItemsRef.current.push(r)}>
          <Slider ref={thumbnailsSliderRef} {...thumbnailSliderSettings}>
            {teamMembers.map((member, i) => (
              <div key={member.name}>
                <Thumbnail
                  active={i === activeMember}
                  onClick={() => {
                    thumbnailsSliderRef.current.slickGoTo(i);
                  }}
                >
                  <Image
                    width={124}
                    height={124}
                    src={member.thumbnail}
                    alt={member.name}
                  />
                </Thumbnail>
              </div>
            ))}
          </Slider>
        </ThumbnailsSlider>
      </div>
    </TeamSection>
  );
};

export default Team;
