import Image from 'next/image';
import { AboutSection, Block } from '@/scenes/Home/About/styles';
import Arrow from '@/assets/arrow.svg';
import Bear from '@/assets/bear-pic.webp';
import { Fragment, useEffect, useRef, useState } from 'react';
import { gsap, Power4 } from 'gsap';
import { SplitText } from '@/utils/gsap/SplitText';
import uniq from 'lodash.uniq';

interface Props {
  title: string;
  subtitle: string;
  description: string;
  stat: string;
  statText: string;
  secondStat: string;
  secondStatText: string;
}

const About = ({
  title,
  subtitle,
  description,
  stat,
  statText,
  secondStat,
  secondStatText,
}: Props) => {
  // TODO: make reusable when approved
  const animatedItems = useRef([]);
  const blockRef1 = useRef(null);
  const blockRef2 = useRef(null);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;

    gsap.set(blockRef1.current, { borderColor: 'transparent' });

    gsap.set('.animated-arrows svg', {
      scaleY: 1,
      yPercent: 100,
      opacity: 0,
    });

    const arrowTl = gsap.timeline({
      scrollTrigger: {
        trigger: '.animated-arrows',
        start: 'top 92%',
      },
    });

    arrowTl.to('.animated-arrows svg', {
      stagger: 0.075,
      duration: 0.75,
      scaleY: 1,
      opacity: 1,
      yPercent: 0,
      delay: 0.2,
      ease: Power4.easeInOut,
    });

    [blockRef1.current, blockRef2.current].forEach((block) => {
      gsap.set(block, { borderColor: 'transparent' });

      let blockTl = gsap.timeline({
        scrollTrigger: {
          trigger: block,
          start: 'top 92%',
        },
      });

      blockTl.to(block, {
        borderColor: '#090909',
        duration: 0.75,
        delay: 0.2,
        ease: Power4.easeInOut,
      });
    });

    uniq(animatedItems.current.filter((i) => i)).forEach((element) => {
      const childSplit = new SplitText(element, {
        type: 'lines',
        linesClass: 'animatedLineChild',
      });
      const parentSplit = new SplitText(element, {
        type: 'lines',
        linesClass: 'animatedLineParent',
      });

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: 'top 92%',
        },
      });
      tl.fromTo(
        childSplit.lines,
        {
          transition: 'none',
          transformOrigin: '0% 0%',
          scaleY: 1,
          yPercent: 100,
        },
        {
          duration: 0.75,
          transformOrigin: '0% 0%',
          delay: 0.2,
          scaleY: 1,
          opacity: 1,
          y: 0,
          yPercent: 0,
          stagger: 0.075,
          ease: Power4.easeInOut,
          onComplete: () => {
            // @ts-ignore
            // childSplit.revert();
          },
        },
      ).set(parentSplit.lines, { overflow: 'visible' }, 0.2);
    });
  }, [loaded]);

  return (
    <AboutSection className="text-dark">
      <div className="container">
        <div className="grid">
          <div className="col-xs-12 col-md-7 title">
            <h2 ref={(r) => animatedItems.current.push(r)}>
              {title.split('\n').map((i, index) => (
                <Fragment key={i}>
                  {index !== 0 && <br />}
                  {i}
                </Fragment>
              ))}
            </h2>
          </div>
          <div className="col-xs-12 col-md-5">
            <Block centered ref={blockRef1}>
              <h2
                className="subtitle"
                ref={(r) => animatedItems.current.push(r)}
              >
                {subtitle}
              </h2>
              <p ref={(r) => animatedItems.current.push(r)}>{description}</p>
            </Block>
          </div>
          <div className="col-xs-12 col-md-5">
            <Block rectangle grid className="collection-block" ref={blockRef2}>
              <h3
                className="number-title"
                ref={(r) => animatedItems.current.push(r)}
              >
                {stat}
              </h3>
              <div className="arrows animated-arrows">
                <Arrow />
                <Arrow />
                <Arrow />
              </div>
              <p ref={(r) => animatedItems.current.push(r)}>{statText}</p>
            </Block>
          </div>
          <div className="col-xs-12 col-md-7">
            <Block colored grid>
              <h3
                className="full-width"
                ref={(r) => animatedItems.current.push(r)}
              >
                {secondStat}
              </h3>
              <p ref={(r) => animatedItems.current.push(r)}>{secondStatText}</p>
              <div className="bear-image">
                <Image priority src={Bear} layout="fill" alt={secondStatText} />
              </div>
            </Block>
          </div>
        </div>
      </div>
    </AboutSection>
  );
};

export default About;
