import { StyledTitle } from '@/components/TickerTitle/styles';
import { gsap, Power0 } from 'gsap';
import {
  FC,
  forwardRef,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IUIStore } from '@/stores/UI';
import { inject, observer } from 'mobx-react';

interface Props extends PropsWithChildren {
  uiStore: IUIStore;
}

const TickerTitle: FC<any> = forwardRef(
  ({ children, uiStore: { preloaded } }: Props, ref) => {
    const tickerRef = useRef(null);
    const textRef = useRef(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      setLoaded(true);
    }, []);

    useEffect(() => {
      if (!loaded || !preloaded) return;

      const textWidth = textRef.current.offsetWidth;

      const tl = gsap.timeline({ repeat: -1 });

      tl.to(tickerRef.current, {
        duration: 15,
        x: `-=${textWidth}`,
        ease: Power0.easeNone,
        onComplete: () => {
          if (!tickerRef.current || !textRef.current) return;

          const style = window.getComputedStyle(tickerRef.current);
          const matrix = new WebKitCSSMatrix(style.webkitTransform);

          if (matrix.m41 <= -textWidth) {
            gsap.set(tickerRef.current, {
              x: 0,
            });
          }

          tl.invalidate();
        },
      });

      return () => {
        tl.kill();
      };
    }, [loaded, preloaded]);

    return (
      <StyledTitle
        ref={(r) => {
          tickerRef.current = r;
          // @ts-ignore
          ref.current = r;
        }}
        className="ticker-title text-branded"
      >
        <span ref={textRef}>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </StyledTitle>
    );
  },
);

TickerTitle.displayName = 'TickerTitle';

export default inject('uiStore')(observer(TickerTitle));
