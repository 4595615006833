import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const TeamSection = styled.section`
  overflow: hidden;
  position: relative;
  padding: 45px 0;

  ${breakpoints.MD} {
    padding: 127px 0 0 0;
  }
`;

const Title = styled.h2`
  margin-left: 25%;

  ${breakpoints.MD} {
    margin-left: calc(41.67% + 12px);
  }
`;

const TeamSlider = styled.div`
  position: relative;

  .slick-list {
    margin-left: -16px;

    ${breakpoints.MD} {
      margin-left: -40px;
    }

    ${breakpoints.MDL} {
      margin-left: -60px;
    }

    ${breakpoints.LG} {
      margin-left: -60px;
    }
  }
`;

const ThumbnailsSlider = styled.div`
  display: none;
  z-index: 10;

  ${breakpoints.MDL} {
    display: block;
    position: absolute;
    bottom: -8px;
    width: calc(58.33% - 85px);
    left: 43.4%;
  }

  ${breakpoints.LG} {
    display: block;
    position: absolute;
    bottom: -8px;
    width: calc(58.33% - 25px);
    left: 43.2%;
  }

  ${breakpoints.XXLG} {
    max-width: 1100px;
    left: 42.6vw;
  }

  .slick-slider {
    overflow: hidden;
  }

  .slick-list {
    margin-left: -9px;
    overflow: visible;
  }

  .slick-slide {
    padding-left: 9px;
  }
`;

const Thumbnail = styled.div`
  overflow: hidden;
  transition: border-radius 0.3s ease-in-out;

  ${breakpoints.MDL} {
    width: 78px;
    height: 78px;
  }

  ${breakpoints.LG} {
    width: 124px;
    height: 124px;
  }

  ${({ active }) =>
    active &&
    css`
      border-radius: 50%;
    `}
`;

const Member = styled.article`
  padding-top: 60px;
  position: relative;
  display: grid;
  row-gap: 32px;
  column-gap: 18px;
  grid-template-columns: repeat(4, 4fr);
  overflow: hidden;
  padding-left: 16px;
  grid-template-areas:
    'title'
    'position'
    'info';
  min-height: 664px;
  place-content: start;

  &:after {
    content: '';
    z-index: 2;
    height: 75px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, #0b0a0a 0%, rgba(0, 0, 0, 0) 100%);
    transform: rotate(-180deg);

    ${breakpoints.MD} {
      width: 45%;
      left: 0;
    }

    ${breakpoints.LG} {
      width: 40%;
    }
  }

  ${breakpoints.MD} {
    grid-template-columns: repeat(12, 12fr);
    padding-left: 51px;
    padding-top: 70px;
    row-gap: 30px;
    min-height: unset;
  }

  ${breakpoints.MDL} {
    padding-bottom: 131px;
    row-gap: 16px;
    padding-left: 68px;
  }

  ${breakpoints.LG} {
    padding-bottom: 179px;
    row-gap: 23px;
    padding-left: 68px;
  }
`;

const Name = styled.h3`
  grid-area: title;
  grid-column-start: 2;
  grid-column-end: 5;

  ${breakpoints.MD} {
    grid-column-start: 6;
    grid-column-end: 13;
    font-size: 62px;
    line-height: 48px;
  }

  ${breakpoints.LG} {
    font-size: 80px;
    line-height: 68px;
  }
`;

const Position = styled.p`
  grid-area: position;
  grid-column-start: 2;
  grid-column-end: 4;
  margin-top: -16px;
  text-align: left;
  display: inline;

  ${breakpoints.MD} {
    grid-column-start: 6;
    grid-column-end: 9;
    padding-right: 5%;
  }

  ${breakpoints.MDL} {
    grid-column-end: 8;
    margin-top: 0;
  }

  ${breakpoints.LG} {
    grid-column-end: 8;
    margin-top: 0;
    min-height: 50px;
  }
`;

const ToggleInfoButton = styled.button`
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.primary.first};
  text-transform: none;
  padding: 0;
  font-weight: 400;
  place-self: start;
  margin-left: calc(25% + 5px);

  ${breakpoints.MD} {
    display: none;
  }
`;

const MemberImage = styled.div`
  position: absolute;
  bottom: -40px;
  left: -94px;
  z-index: -1;
  pointer-events: none;

  ${breakpoints.MD} {
    left: -195px;

    img {
      width: 550px !important;
      height: 600px !important;
    }
  }

  ${breakpoints.MDL} {
    left: -180px;

    img {
      width: 640px !important;
      height: 680px !important;
    }
  }

  ${breakpoints.LG} {
    left: -129px;

    img {
      width: 740px !important;
      height: 790px !important;
    }
  }

  ${breakpoints.XXLG} {
    left: 0;
  }
`;

const Info = styled.div`
  grid-area: info;
  grid-column: span 4;
  margin-left: -16px;
  margin-top: -32px;
  padding: ${({ theme }) => `0 0 78px ${theme.spacings.x2}`};
  height: 506px;
  position: relative;
  z-index: 20;

  ${breakpoints.MD} {
    grid-column-start: 6;
    grid-column-end: 13;
    height: unset;
    padding-bottom: 0;
  }

  .wrapper {
    padding-bottom: 32px;
    padding-right: 25%;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;

    ${breakpoints.MD} {
      height: unset;
      visibility: visible;
      opacity: 1;
      padding-right: 0;
      padding-top: ${({ theme }) => theme.spacings.x4};
      padding-bottom: 0;
    }

    ${breakpoints.LG} {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-end;
      padding-top: 86px;
    }
  }

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.colors.bg};
      padding-top: ${theme.spacings.x4};

      .wrapper {
        height: unset;
        visibility: visible;
        opacity: 1;
      }
    `}
`;

const Bio = styled.p`
  margin-bottom: ${({ theme }) => theme.spacings.x4};

  ${breakpoints.MD} {
    margin-left: 45.86%;
    margin-bottom: ${({ theme }) => theme.spacings.x7};
  }

  ${breakpoints.LG} {
    margin-left: 0;
    margin-bottom: 24px;
    width: 40%;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
  }
`;

const Skills = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${breakpoints.MD} {
    width: 68.43%;
  }

  ${breakpoints.MDL} {
    width: 54.6%;
  }

  ${breakpoints.LG} {
    width: 40%;
  }
`;

const Skill = styled.li`
  display: flex;
  flex-flow: row nowrap;
  padding: 10px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.divider};
  justify-content: space-between;
  align-content: center;
  align-items: center;

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  }

  ${breakpoints.MD} {
    padding: 16.5px 0;
  }
`;

const SkillLabel = styled.p``;

const SkillScale = styled.div`
  width: 71px;
  height: 27px;
  border: 1px solid #fff;
  border-radius: 33px;
  padding: 6px 12px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  span {
    height: 100%;
    width: 3px;
    background-color: ${({ theme }) => theme.colors.secondary.second};
  }

  ${breakpoints.LG} {
    width: 84px;
    height: 32px;
    padding: 8px 16px;

    span {
      width: 4px;
    }
  }
`;

const SliderArrows = styled.div`
  position: absolute;
  left: 25%;
  bottom: 0;

  ${breakpoints.MD} {
    left: 0;
  }
`;

export {
  TeamSection,
  Title,
  TeamSlider,
  Name,
  Position,
  Bio,
  ToggleInfoButton,
  Info,
  SliderArrows,
  Member,
  MemberImage,
  Skills,
  Skill,
  SkillLabel,
  SkillScale,
  ThumbnailsSlider,
  Thumbnail,
};
