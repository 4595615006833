import styled, { css } from 'styled-components';
import { baseFontSize } from '@/styles/theme';
import { breakpoints } from '@/styles/breakpoints';

const AboutSection = styled.section`
  padding: ${({ theme }) => `${parseInt(theme.spacings.x7) + 4}px 0
   ${theme.spacings.x2}`};
  background-color: ${({ theme }) => theme.colors.primary.second};
  border-radius: ${({ theme }) => theme.spacings.x6};

  .grid {
    grid-row-gap: ${({ theme }) => theme.spacings.x4};

    ${breakpoints.MD} {
      grid-row-gap: ${({ theme }) => theme.spacings.x7};
    }

    ${breakpoints.LG} {
      grid-row-gap: ${({ theme }) => theme.spacings.x6};
    }

    ${breakpoints.XXLG} {
      grid-row-gap: ${({ theme }) => parseInt(theme.spacings.x4) + 6}px;
    }
  }

  ${breakpoints.MD} {
    padding-bottom: ${({ theme }) => theme.spacings.x7};
    border-radius: 100px;

    .container > .grid {
      & > * {
        order: 3;
      }

      & > *:nth-child(2) {
        order: 1;
      }

      .title {
        order: 2;
        position: relative;
        top: 8px;
      }
    }
  }

  ${breakpoints.MDL} {
    padding: ${({ theme }) => `${parseInt(theme.spacings.x10) + 4}px 0
   ${theme.spacings.x10}`};
  }

  ${breakpoints.LG} {
    padding: ${({ theme }) => `${parseInt(theme.spacings.x10)}px 0
   ${theme.spacings.x10}`};
  }

  ${breakpoints.XXLG} {
    padding-bottom: ${({ theme }) => theme.spacings.x8};
  }

  .number-title {
    font-size: ${baseFontSize * 7.2}px;
    line-height: 0.89;

    ${breakpoints.MD} {
      font-size: 116px;
      line-height: 102px;
      margin-bottom: -10px;
    }

    ${breakpoints.MDL} {
      font-size: 126px;
      line-height: 108px;
    }

    ${breakpoints.LG} {
      font-size: 140px;
      line-height: 120px;
    }

    ${breakpoints.XXLG} {
      font-size: 196px;
      line-height: 180px;
    }
  }
`;

interface BlockProps {
  rectangle?: boolean;
  colored?: boolean;
  grid?: boolean;
  centered?: boolean;
}

const Block = styled.div<BlockProps>`
  padding: ${({ theme }) => `${theme.spacings.x4} 0`};
  border: 2px solid ${({ theme }) => theme.colors.bg};
  border-left: none;
  border-right: none;
  position: relative;
  overflow: hidden;

  ${breakpoints.MD} {
    height: 100%;
    align-content: start;
    padding-left: 12%;
  }

  ${({ theme, rectangle }) =>
    rectangle &&
    css`
      border: 2px solid ${theme.colors.bg};
      padding: ${theme.spacings.x4};
    `}

  ${({ theme, centered }) =>
    centered &&
    css`
      ${breakpoints.MD} {
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        align-items: center;
      }

      ${breakpoints.MDL} {
        padding: ${parseInt(theme.spacings.x2) + 1}px 0
          ${parseInt(theme.spacings.x2) + 1}px ${theme.spacings.x6};
      }

      ${breakpoints.LG} {
        padding: ${parseInt(theme.spacings.x2) - 3}px
          ${parseInt(theme.spacings.x7) + 8}px
          ${parseInt(theme.spacings.x2) + 1}px ${theme.spacings.x6};
      }

      ${breakpoints.XXLG} {
        padding: 0 ${parseInt(theme.spacings.x14) - 8}px
          ${parseInt(theme.spacings.x3)}px ${theme.spacings.x7};
      }
    `}

  ${({ theme, grid }) =>
    grid &&
    css`
      display: grid;
      grid-row-gap: ${theme.spacings.x4};
      grid-template-columns: repeat(12, 12fr);

      h2,
      h3 {
        grid-column: span 7;
      }

      p {
        grid-column: span 12;
      }

      .full-width {
        grid-column: span 12;
      }
    `}

  .arrows {
    display: flex;
    justify-content: flex-end;
    grid-column: span 5;

    ${breakpoints.MD} {
      order: -1;
      grid-column: span 12;
      justify-content: flex-start;
    }

    ${breakpoints.MDL} {
      order: 0;
      grid-column: span 5;
      justify-content: flex-end;
      position: relative;
      top: -6px;
    }

    ${breakpoints.LG} {
      order: 0;
      grid-column: span 5;
      justify-content: flex-end;
      position: relative;
      top: -6px;
    }

    svg {
      & + svg {
        margin-left: ${({ theme }) => theme.spacings.base};
      }
    }
  }

  ${({ theme, colored }) =>
    colored &&
    css`
      padding: ${theme.spacings.x4} ${theme.spacings.x4}
        ${parseInt(theme.spacings.x20) + parseInt(theme.spacings.x3) + 'px'};
      border: none;
      background-color: ${({ theme }) => theme.colors.secondary.first};
      border-radius: 0 ${theme.spacings.x7} ${theme.spacings.x7}
        ${theme.spacings.x7};

      .bear-image {
        position: absolute;
        bottom: -42px;
        left: -39px;
        max-width: 302px;
        max-height: 232px;
        width: 46vw;
        height: 41vw;
        //mix-blend-mode: darken;

        ${breakpoints.MD} {
          max-width: 407px;
          max-height: 312px;
          width: 26vw;
          height: 21vw;
          bottom: -42px;
          left: -39px;
        }

        ${breakpoints.MDL} {
          max-width: 407px;
          max-height: 312px;
          width: 30vw;
          height: 23vw;
          bottom: -9px;
          left: -95px;
        }

        ${breakpoints.LG} {
          max-width: 471px;
          max-height: 324px;
          width: 29vw;
          height: 20vw;
          bottom: -3px;
          left: -3px;
        }

        ${breakpoints.XXLG} {
          width: 629px;
          height: 396px;
          width: 19vw;
          height: 13vw;
          left: 12%;
          bottom: 0;
        }
      }

      ${breakpoints.MD} {
        padding-top: ${({ theme }) => parseInt(theme.spacings.x7) + 6}px;
        padding-bottom: ${({ theme }) => parseInt(theme.spacings.x10) - 7}px;
        padding-left: 45%;

        border-radius: ${theme.spacings.x7} ${theme.spacings.x7}
          ${theme.spacings.x7} 0;
      }

      ${breakpoints.LG} {
        padding: ${parseInt(theme.spacings.x7) - 2}px
          ${parseInt(theme.spacings.x5)}px ${parseInt(theme.spacings.x4) + 3}px
          60%;
      }

      ${breakpoints.XXLG} {
        padding: ${parseInt(theme.spacings.x7) + 11}px
          ${parseInt(theme.spacings.x5)}px ${parseInt(theme.spacings.x4) + 3}px
          63%;

        grid-row-gap: 18px;

        p {
          padding-right: 20%;
        }
      }
    `}

  &.collection-block {
    ${breakpoints.MDL} {
      padding: ${({ theme }) => parseInt(theme.spacings.x7) + 4}px
        ${({ theme }) => parseInt(theme.spacings.x4) - 2}px
        ${({ theme }) => parseInt(theme.spacings.x7) - 4}px;
    }
    ${breakpoints.LG} {
      padding: ${({ theme }) => parseInt(theme.spacings.x7) + 10}px
        ${({ theme }) => parseInt(theme.spacings.x7) - 4}px
        ${({ theme }) => parseInt(theme.spacings.x6) - 3}px;

      grid-row-gap: ${({ theme }) => theme.spacings.x3};
    }

    ${breakpoints.XXLG} {
      padding: ${({ theme }) => parseInt(theme.spacings.x7) + 10}px
        ${({ theme }) => parseInt(theme.spacings.x7) - 4}px
        ${({ theme }) => parseInt(theme.spacings.x7) - 2}px;

      grid-row-gap: 12px;

      p {
        padding-right: 33%;
      }
    }
  }
`;

export { AboutSection, Block };
