import styled from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const StoriesSection = styled.section`
  overflow: hidden;

  ${breakpoints.XXLG} {
    padding-top: 140px;
  }

  .slider-cutter {
    ${breakpoints.MD} {
      width: calc(125% + ${({ theme }) => theme.spacings.x4});
      overflow: hidden;
    }
  }
`;

const StoriesSlider = styled.div`
  padding-top: 10px;

  ${breakpoints.MD} {
    width: calc(100vw - 200px);
    padding-top: 0;
  }

  ${breakpoints.MDL} {
    width: calc(100vw - 435px);
    padding-top: 0;
  }

  ${breakpoints.LG} {
    width: calc(100vw - 124px);
  }

  ${breakpoints.XXLG} {
    width: 1808px;
  }

  .slick-list {
    margin-left: -${({ theme }) => theme.spacings.x2};

    ${breakpoints.MD} {
      margin-left: -${({ theme }) => theme.spacings.x4};
      overflow: visible;
    }
  }

  .slick-slide {
    padding-left: ${({ theme }) => theme.spacings.x2};
    position: relative;
    left: -1px;

    ${breakpoints.MD} {
      padding-left: ${({ theme }) => theme.spacings.x4};
    }
  }
`;

export { StoriesSection, StoriesSlider };
