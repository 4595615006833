import styled from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const ParallaxWrapper = styled.div`
  position: relative;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.bg};

  ${breakpoints.LG} {
    margin-top: 100vh;
  }
`;

export { ParallaxWrapper };
