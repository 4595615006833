import styled, { css, keyframes } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const AbominationsSection = styled.section`
  position: relative;
  user-select: none;

  .title {
    position: relative;
    left: -6vw;
    font-size: 20.8vw;

    ${breakpoints.MD} {
      left: 0;
      font-size: 128px;
      line-height: 1;
      letter-spacing: -0.01em;
    }

    ${breakpoints.MDL} {
      font-size: 172px;
    }

    ${breakpoints.LG} {
      font-size: 240px;
    }

    ${breakpoints.XXLG} {
      font-size: 340px;
      top: -14px;
    }
  }

  .description {
    padding-right: 5%;

    ${breakpoints.MD} {
      padding-right: 10%;
    }
  }

  .cta {
    ${breakpoints.MD} {
      margin-top: ${({ theme }) => theme.spacings.x13};
      padding-left: 10%;
    }

    ${breakpoints.MDL} {
      padding-left: 6%;
    }

    ${breakpoints.LG} {
      margin-top: ${({ theme }) => theme.spacings.x10};
      padding-left: 8%;
    }

    ${breakpoints.XXLG} {
      padding-left: 5%;
    }

    &__text {
      padding-left: 2%;

      ${breakpoints.MD} {
        padding: 0;
      }

      ${breakpoints.XXLG} {
        br {
          display: none;
        }
      }
    }

    &__action {
      line-height: 20px;
      margin-top: 456px;
      margin-left: auto;

      ${breakpoints.MD} {
        margin-left: 0;
        margin-top: ${({ theme }) => theme.spacings.x4};
      }

      ${breakpoints.XXLG} {
        margin-top: ${({ theme }) => parseInt(theme.spacings.x6) + 2}px;
      }

      .wrap {
        display: flex;
        align-content: center;
        align-items: center;
      }
    }
  }
`;

interface AbominationsLayerProps {
  top?: boolean;
}

const animate1 = keyframes`
  0% {
    opacity: 0;
  }
  
  25% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
  
  75% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const animate2 = keyframes`
  0% {
    transform: translateX(0);
  }
  
  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(0);
  }
  
  75% {
    transform: translateX(-5px);
  }

  90% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
`;

const Helper = styled.div`
  position: absolute;
  right: 18%;
  top: 680px;
  z-index: 100;
  pointer-events: none;

  ${breakpoints.MDL} {
    display: none !important;
  }

  ${breakpoints.LG} {
    display: none !important;
  }

  &:before,
  &:after {
    content: '';
    width: 20px;
    height: 1px;
    background-color: rgba(70, 68, 68, 0.68);
    position: absolute;
    top: -3px;
    left: -15px;
    animation: 2s linear infinite ${animate1};
  }

  svg {
    animation: 2s linear infinite ${animate2};
  }

  &:after {
    width: 1px;
    height: 20px;
    left: -5px;
    top: -13px;
  }

  .text {
    position: absolute;
    width: 150px;
    right: -60px;
    top: 110%;
    text-transform: uppercase;
    font-weight: 500;
  }
`;

const AbominationsLayer = styled.div<AbominationsLayerProps>`
  isolation: isolate;
  width: 100%;
  position: relative;
  padding: ${({ theme }) =>
    `${parseInt(theme.spacings.x4) + 5}px 0 ${theme.spacings.x10}`};
  border-radius: ${({ theme }) => theme.spacings.x6};
  background-color: ${({ theme }) => theme.colors.primary.first};
  overflow: hidden;

  ${breakpoints.MD} {
    padding: ${({ theme }) =>
      `${theme.spacings.x10} 0 ${parseInt(theme.spacings.x13) - 1}px`};
    border-radius: 100px;
  }

  ${breakpoints.MDL} {
    padding: ${({ theme }) =>
      `${theme.spacings.x10} 0 ${parseInt(theme.spacings.x15) + 5}px`};
  }

  ${breakpoints.LG} {
    padding: ${({ theme }) =>
      `${theme.spacings.x10} 0 ${parseInt(theme.spacings.x15) + 5}px`};
  }

  ${breakpoints.XXLG} {
    padding: ${({ theme }) => `${theme.spacings.x10} 0 290px`};
  }

  ${({ theme, top }) => {
    if (top) {
      return css`
        clip-path: circle(130px at 264px 580px);
        z-index: 10;
        will-change: clip-path;

        ${breakpoints.MD} {
          clip-path: circle(130px at 398px 386px);
        }

        ${breakpoints.MDL} {
          clip-path: circle(150px at 481px 343px);
        }

        ${breakpoints.LG} {
          clip-path: circle(0px at 0px 0px);
        }

        * {
          color: ${theme.colors.text.dark};

          &.text-branded {
            color: ${theme.colors.primary.second};
          }
        }
      `;
    } else {
      return css`
        background-color: ${theme.colors.bg};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        .title {
          color: #5a5a5a;
        }
      `;
    }
  }}

  .bg-pattern {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;

    &--fog {
      z-index: 4;
      left: unset;

      & > * {
        bottom: -12px !important;
      }
    }

    &:not(.bg-pattern--fog) {
      max-width: 100%;
      max-height: 100%;
      bottom: -7px;
    }
  }
`;

const AbominationsGrid = styled.div`
  position: relative;
  z-index: 5;
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  row-gap: ${({ theme }) => theme.spacings.x7};
  grid-template-areas:
    'title .'
    'description .'
    '. cta';

  ${breakpoints.MD} {
    grid-template-columns: repeat(12, 12fr);
    row-gap: ${({ theme }) => parseInt(theme.spacings.x4) + 2}px;
    grid-template-areas:
      'title . .'
      'description . cta';
  }

  ${breakpoints.MDL} {
    row-gap: ${({ theme }) => parseInt(theme.spacings.x3) + 2}px;
  }

  ${breakpoints.XXLG} {
    row-gap: 0;
  }

  .title {
    grid-area: title;
    grid-column: span 4;

    ${breakpoints.MD} {
      grid-column: span 12;
    }
  }

  .description {
    grid-area: description;
    grid-column-start: 1;
    grid-column-end: 3;

    ${breakpoints.MD} {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    ${breakpoints.LG} {
      grid-column-end: 3;
    }
  }

  .cta {
    grid-area: cta;
    grid-column-start: 2;
    grid-column-end: 5;

    ${breakpoints.MD} {
      grid-column-start: 9;
      grid-column-end: 13;
    }

    ${breakpoints.LG} {
      grid-column-start: 10;
    }
  }
`;

const AbominationsBear = styled.div`
  z-index: 3;
  position: absolute;
  bottom: -10px;
  left: -207px;

  ${breakpoints.MD} {
    left: 5.6%;
    bottom: -19px;
  }

  ${breakpoints.MDL} {
    left: 4.8%;
    bottom: -48px;
  }

  ${breakpoints.LG} {
    left: 28.2%;
    bottom: -7px;
  }

  svg {
    min-width: 660px !important;
    width: 660px !important;
    height: 647px !important;

    ${breakpoints.MD} {
      min-width: 457px !important;
      width: 457px !important;
      height: 457px !important;
    }

    ${breakpoints.MDL} {
      min-width: 627px !important;
      width: 627px !important;
      height: 615px !important;
    }

    ${breakpoints.LG} {
      min-width: 627px !important;
      width: 627px !important;
      height: 615px !important;
    }

    ${breakpoints.XXLG} {
      min-width: 864px !important;
      width: 864px !important;
      height: 848px !important;
    }
  }
`;

const AbominationsTicker = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${({ theme }) => theme.spacings.x2};
  display: flex;
  flex-flow: row nowrap;
  z-index: 6;
  will-change: transform;

  && {
    ${breakpoints.MD} {
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      letter-spacing: 0.002em;
      bottom: 21px;
    }

    ${breakpoints.MDL} {
      font-size: 24px;
      line-height: 32px;
      bottom: 16px;
    }

    ${breakpoints.LG} {
      font-size: 24px;
      line-height: 32px;
      bottom: 16px;
    }

    ${breakpoints.XXLG} {
      font-size: 32px;
      line-height: 40px;
    }
  }

  span {
    width: auto;
    white-space: nowrap;

    & + span {
      position: relative;
      margin-left: ${({ theme }) => theme.spacings.x5};

      ${breakpoints.MD} {
        margin-left: ${({ theme }) => parseInt(theme.spacings.x5) - 2}px;
      }

      ${breakpoints.MDL} {
        margin-left: ${({ theme }) => theme.spacings.x5};
      }

      ${breakpoints.LG} {
        margin-left: ${({ theme }) => theme.spacings.x5};
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -24px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: currentColor;

        ${breakpoints.MD} {
          width: 6px;
          height: 6px;
          left: -22px;
        }

        ${breakpoints.MDL} {
          left: -24px;
          width: 8px;
          height: 8px;
        }

        ${breakpoints.LG} {
          left: -24px;
          width: 8px;
          height: 8px;
        }
      }
    }
  }
`;

export {
  AbominationsSection,
  AbominationsLayer,
  AbominationsGrid,
  AbominationsBear,
  AbominationsTicker,
  Helper,
};
