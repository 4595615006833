import styled from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const StyledBanner = styled.section`
  overflow: hidden;
  padding: ${({ theme }) => theme.spacings.x4} 0
    ${({ theme }) => theme.spacings.x10};
  will-change: transform;

  ${breakpoints.MD} {
    padding: ${({ theme }) =>
        parseInt(theme.spacings.x20) + parseInt(theme.spacings.x20) + 10}px
      0 ${({ theme }) => theme.spacings.x10};
  }

  ${breakpoints.MDL} {
    padding: ${({ theme }) =>
        parseInt(theme.spacings.x25) + parseInt(theme.spacings.x16) + 7}px
      0 ${({ theme }) => theme.spacings.x10};
  }

  ${breakpoints.LG} {
    padding: ${({ theme }) =>
        parseInt(theme.spacings.x25) + parseInt(theme.spacings.x18)}px
      0 ${({ theme }) => parseInt(theme.spacings.x20) + 6}px;
    position: fixed;
    top: 86px;
    left: 0;
    right: 0;
  }

  ${breakpoints.XXLG} {
    padding: 20vw 0 200px;
  }

  @media (min-width: 2400px) {
    padding: 25vw 0 300px;
  }

  .grid {
    grid-row-gap: ${({ theme }) => theme.spacings.x7};

    ${breakpoints.MD} {
      grid-row-gap: ${({ theme }) => theme.spacings.x14};
    }

    ${breakpoints.MD} {
      grid-row-gap: ${({ theme }) => parseInt(theme.spacings.x14) - 1}px;
    }

    ${breakpoints.LG} {
      grid-row-gap: ${({ theme }) => theme.spacings.x10};
    }

    ${breakpoints.XXLG} {
      grid-row-gap: ${({ theme }) => theme.spacings.x16};
    }

    .right-aligned {
      grid-column: 4 / span 10;

      ${breakpoints.MD} {
        order: 2;
        grid-column: 9 / span 4;
      }

      ${breakpoints.LG} {
        grid-column: 10 / span 3;
        padding-top: ${({ theme }) => theme.spacings.x5};
      }
    }
  }

  .ticker-title {
    position: absolute;
    left: -${({ theme }) => theme.spacings.x2};
    top: ${({ theme }) => parseInt(theme.spacings.x3) + 3}px;
    letter-spacing: 0.02em;

    ${breakpoints.MD} {
      left: -${({ theme }) => parseInt(theme.spacings.base) + 4}px;
      top: ${({ theme }) => parseInt(theme.spacings.x10) - 6}px;
      letter-spacing: -0.01em;
      opacity: 0;
    }

    ${breakpoints.MDL} {
      left: -${({ theme }) => parseInt(theme.spacings.x2) + 5}px;
      top: ${({ theme }) => parseInt(theme.spacings.x10) - 6}px;
    }

    ${breakpoints.LG} {
      left: -${({ theme }) => parseInt(theme.spacings.x3) + 1}px;
      top: ${({ theme }) => parseInt(theme.spacings.x10) - 2}px;
    }

    ${breakpoints.XXLG} {
      top: ${({ theme }) => parseInt(theme.spacings.x10) - 4}px;
      left: -${({ theme }) => parseInt(theme.spacings.x3) + 4}px;
      letter-spacing: -0.008em;
    }

    @media (min-width: 2400px) {
      top: ${({ theme }) => parseInt(theme.spacings.x25) - 4}px;
    }
  }

  .sphere {
    min-height: 338px;

    ${breakpoints.MD} {
      position: absolute;
      top: ${({ theme }) => parseInt(theme.spacings.x10) + 14}px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
    }

    ${breakpoints.MDL} {
      top: ${({ theme }) => parseInt(theme.spacings.x10) + 3}px;
    }

    ${breakpoints.LG} {
      top: ${({ theme }) => parseInt(theme.spacings.x10)}px;
      left: 49.5%;
    }

    ${breakpoints.XXLG} {
      top: ${({ theme }) => parseInt(theme.spacings.x10)}px;
      left: 49.5%;
    }

    @media (min-width: 2400px) {
      top: ${({ theme }) => parseInt(theme.spacings.x25)}px;
    }
  }

  .banner-description {
    margin-top: ${({ theme }) => theme.spacings.base};
  }

  .text-1 {
    padding-right: ${({ theme }) => parseInt(theme.spacings.x2) + 4}px;

    ${breakpoints.MD} {
      padding-right: 0;
      opacity: 0;
    }

    ${breakpoints.XXLG} {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .text-2 {
    padding-left: ${({ theme }) => parseInt(theme.spacings.base) / 2}px;

    ${breakpoints.MD} {
      padding-left: 0;
      opacity: 0;
    }

    ${breakpoints.XXLG} {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .cta {
    & > * {
      margin: 0 auto;

      ${breakpoints.MD} {
        margin: 0;
      }
    }

    ${breakpoints.MD} {
      align-self: end;
      grid-column: 2 / span 3;
      opacity: 0;
    }

    ${breakpoints.LG} {
      align-self: start;
      grid-column: 2 / span 2;
    }

    ${breakpoints.XXLG} {
      align-self: end;
      padding-top: ${({ theme }) => theme.spacings.x4};
    }
  }
`;

export { StyledBanner };
