import {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SliderAction,
  SliderArrows,
  SliderContent,
  SliderCounter,
  SliderFooter,
  SliderHeader,
  StyledSliderSection,
} from '@/scenes/common/SliderSection/styles';
import SliderArrow from '@/components/Slider/Arrow';
import { gsap, Power4 } from 'gsap';
import { SplitText } from '@/utils/gsap/SplitText';
import uniq from 'lodash.uniq';

interface SliderSectionProps {
  title: string;
  counter: SliderCounterProps;
  action: ReactElement;
  onPrevSlide: () => void;
  onNextSlide: () => void;
  rtl?: boolean;
}

interface SliderCounterProps {
  current: number;
  total: number;
}

const SliderSection = ({
  children,
  title,
  counter: { current, total },
  action,
  onPrevSlide,
  onNextSlide,
  rtl,
}: PropsWithChildren<SliderSectionProps>) => {
  const [inProgress, setInProgress] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const animatedItems = useRef([]);
  const fadeItemsRef = useRef([]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;

    uniq(animatedItems.current.filter((i) => i)).forEach((element) => {
      const childSplit = new SplitText(element, {
        type: 'lines',
        linesClass: 'animatedLineChild',
      });
      const parentSplit = new SplitText(element, {
        type: 'lines',
        linesClass: 'animatedLineParent',
      });

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: 'top 92%',
          toggleActions: 'play none none none',
        },
      });
      tl.fromTo(
        childSplit.lines,
        {
          transition: 'none',
          transformOrigin: '0% 0%',
          scaleY: 1,
          yPercent: 100,
        },
        {
          duration: 0.75,
          transformOrigin: '0% 0%',
          delay: 0.2,
          scaleY: 1,
          opacity: 1,
          y: 0,
          yPercent: 0,
          stagger: 0.075,
          ease: Power4.easeInOut,
          onComplete: () => {
            // @ts-ignore
            childSplit.revert();
          },
        },
      ).set(parentSplit.lines, { overflow: 'visible' }, 0.2);
    });

    uniq(fadeItemsRef.current.filter((i) => i)).forEach((element) => {
      let tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: 'top 92%',
          toggleActions: 'play none none none',
        },
      });
      tl2.fromTo(
        element,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.75,
          delay: 0.2,
          ease: Power4.easeInOut,
        },
      );
    });
  }, [loaded]);

  const onPrev = () => {
    setInProgress(true);
    setTimeout(() => {
      onPrevSlide();
    }, 100);
    setTimeout(() => {
      setInProgress(false);
    }, 600);
  };

  const onNext = () => {
    setInProgress(true);
    setTimeout(() => {
      onNextSlide();
    }, 100);
    setTimeout(() => {
      setInProgress(false);
    }, 600);
  };

  return (
    <StyledSliderSection>
      <SliderHeader rtl={rtl}>
        <h2 className="title" ref={(r) => animatedItems.current.push(r)}>
          {title}
        </h2>
        <SliderCounter ref={(r) => fadeItemsRef.current.push(r)}>
          {current}/{total}
        </SliderCounter>
      </SliderHeader>
      <SliderContent
        blurred={inProgress}
        ref={(r) => fadeItemsRef.current.push(r)}
      >
        {children}
      </SliderContent>
      <SliderFooter ref={(r) => fadeItemsRef.current.push(r)}>
        <SliderAction>{action}</SliderAction>
        <SliderArrows>
          <SliderArrow onClick={onPrev} />
          <SliderArrow onClick={onNext} direction="right" />
        </SliderArrows>
      </SliderFooter>
    </StyledSliderSection>
  );
};

export default SliderSection;
