import styled, { css } from 'styled-components';
import { SliderArrowProps } from '@/components/Slider/Arrow/SliderArrow';

const StyledSliderArrow = styled.button<SliderArrowProps>`
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  width: ${({ theme }) => theme.spacings.x10};
  height: 28px;
  position: relative;

  & + & {
    margin-left: ${({ theme }) => theme.spacings.x4};
  }

  span {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    background-color: #fff;
    height: 2px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 20px;
    background-color: #fff;
    left: -1px;
    top: 50%;
    transform-origin: 0 0;
  }

  span,
  &:before,
  &:after {
    transition: background-color 0.1s ease-in-out;
  }

  &:before {
    transform: translateY(calc(-50% + 1px)) rotate(-45deg);
  }

  &:after {
    left: 1px;
    transform: translateY(calc(-50%)) rotate(45deg);
  }

  &:hover {
    span,
    &:before,
    &:after {
      background-color: ${({ theme }) => theme.colors.text.grey};
    }
  }

  &:active {
    span,
    &:before,
    &:after {
      background-color: ${({ theme }) => theme.colors.primary.first};
    }
  }

  ${({ direction }) =>
    direction === 'right' &&
    css`
      &:before,
      &:after {
        transform-origin: 100% 100%;
        left: unset;
        right: 0;
      }

      &:before {
        right: -1px;
        transform: translateY(calc(-50% - 0.5px)) rotate(-45deg);
      }

      &:after {
        transform: translateY(calc(-50% + 0.5px)) rotate(45deg);
      }
    `}
`;

export { StyledSliderArrow };
