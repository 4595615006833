import Banner from '@/scenes/Home/Banner';
// import About from '@/scenes/Home/About';
// import Club from '@/scenes/Home/Club';
// import RunningBears from '@/scenes/Home/RunningBears';
import Abominations from '@/scenes/Home/Abominations';
import FeaturedBears from '@/scenes/Home/FeaturedBears';
import Stories from '@/scenes/Home/Stories';
import Bearpath from '@/scenes/Home/Bearpath';
import Team from '@/scenes/Home/Team';
import CTA from '@/scenes/common/CTA';
import { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ParallaxWrapper } from '@/scenes/Home/styles';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import useMediaQuery from '@/hooks/useMediaQuery';
import { breakpointsBase } from '@/styles/breakpoints';
import dynamic from 'next/dynamic';
import About from '@/scenes/Home/About';

interface Props {
  content: any;
}

const DynamicClub = dynamic(() => import('@/scenes/Home/Club'));

const DynamicRunningBears = dynamic(
  () => import('@/scenes/Home/RunningBears'),
  {
    ssr: false,
  },
);

const HomeScene = ({ content }: Props) => {
  const parallaxRef = useRef(null);
  const bannerRef = useRef(null);
  const [bannerHeight, setBannerHeight] = useState('100vh');
  const [loaded, setLoaded] = useState(false);
  const isLG = useMediaQuery(breakpointsBase.LG);

  const handleResize = () => {
    if (isLG) {
      setBannerHeight(bannerRef.current.offsetHeight);
    } else {
      setBannerHeight('0');
    }
  };

  useEffect(() => {
    handleResize();
    setLoaded(true);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!loaded || !isLG) return;

    gsap.to(bannerRef.current, {
      y: () => -0.5 * ScrollTrigger.maxScroll(window),
      ease: 'none',
      scrollTrigger: {
        start: 0,
        end: 'max',
        invalidateOnRefresh: true,
        scrub: true,
      },
    });
  }, [loaded]);

  return (
    <main style={{ overflow: 'hidden' }}>
      <Banner ref={bannerRef} {...content.banner} />
      <ParallaxWrapper ref={parallaxRef} style={{ marginTop: bannerHeight }}>
        <About {...content.aboutSection} />
        <DynamicClub {...content.clubSection} />
        <DynamicRunningBears />
        <Abominations {...content.abominationsSection} />
        <FeaturedBears {...content.featuredBearsSection} />
        <Stories {...content.storiesSection} />
        <Bearpath {...content.bearpathSection} />
        <Team team={content.team} />
        <CTA title={content.cta.title} description={content.cta.description} />
      </ParallaxWrapper>
    </main>
  );
};

export default HomeScene;
