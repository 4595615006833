import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const FeaturedBearsSection = styled.section`
  overflow: hidden;

  [class*='styles__SliderFooter'] {
    margin-top: -10px;
  }
`;

const SliderCutter = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-left: -10px;
  margin-top: -10px;
  overflow: hidden;

  ${breakpoints.LG} {
    width: calc(125% + ${({ theme }) => theme.spacings.x4});
    overflow: hidden;
  }
`;

const FeaturedBearsSlider = styled.div`
  ${breakpoints.LG} {
    width: calc(100vw - 124px);
  }

  ${breakpoints.XXLG} {
    width: 1808px;
  }

  .slick-list {
    margin-left: -${({ theme }) => theme.spacings.x2};

    ${breakpoints.MD} {
      margin-left: -${({ theme }) => theme.spacings.x4};
    }

    ${breakpoints.LG} {
      overflow: visible;
    }
  }

  .slick-slide {
    padding-left: ${({ theme }) => theme.spacings.x2};

    &:nth-child(even) {
      position: relative;
      //left: -2px;
    }

    ${breakpoints.MD} {
      padding-left: ${({ theme }) => theme.spacings.x4};

      &:nth-child(3) {
        position: relative;
        //left: -1px;
      }
    }

    ${breakpoints.LG} {
      left: 0 !important;
    }
  }
`;

export { SliderCutter, FeaturedBearsSection, FeaturedBearsSlider };
