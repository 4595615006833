import styled from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const StyledTitle = styled.h1`
  white-space: nowrap;
  will-change: transform;
  pointer-events: none;

  ${breakpoints.XXLG} {
    font-size: 16.50831vw;
  }
`;

export { StyledTitle };
