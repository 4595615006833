import { StyledBanner } from '@/scenes/Home/Banner/styles';
import TickerTitle from '@/components/TickerTitle';
// import MagicSphere from '@/components/MagicSphere';
import Button from '@/components/UI/Button';
import { inject, observer } from 'mobx-react';
import { IUIStore } from '@/stores/UI';
import { FC, forwardRef, useEffect, useRef, useState } from 'react';
import { gsap, Power4 } from 'gsap';
import { nestedLinesSplit } from '@/utils/nestedLinesSplit';
import { Remarkable } from 'remarkable';
import useMediaQuery from '@/hooks/useMediaQuery';
import { breakpointsBase } from '@/styles/breakpoints';
import dynamic from 'next/dynamic';
const md = new Remarkable();

interface BannerProps {
  uiStore: IUIStore;
  description: string;
  subtitle: string;
}

const DynamicMagicSphere = dynamic(() => import('@/components/MagicSphere'), {
  ssr: false,
});

const Banner: FC<any> = forwardRef(
  ({ description, subtitle, uiStore: { preloaded } }: BannerProps, ref) => {
    const tickerRef = useRef(null);
    const sphereRef = useRef(null);
    const textRef1 = useRef(null);
    const textRef2 = useRef(null);
    const actionRef = useRef(null);

    const isMD = useMediaQuery(breakpointsBase.MD);

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      setLoaded(true);
    }, []);

    useEffect(() => {
      if (!loaded || !preloaded || !isMD) return;

      const tl = gsap.timeline();

      const childSplit = nestedLinesSplit(textRef1.current, {
        type: 'lines',
        linesClass: 'animatedLineChild',
      });
      nestedLinesSplit(textRef1.current, {
        type: 'lines',
        linesClass: 'animatedLineParent',
      });

      const childSplit2 = nestedLinesSplit(textRef2.current, {
        type: 'lines',
        linesClass: 'animatedLineChild',
      });
      nestedLinesSplit(textRef2.current, {
        type: 'lines',
        linesClass: 'animatedLineParent',
      });

      gsap.set([textRef1.current, textRef2.current], {
        opacity: 1,
      });

      tl.fromTo(
        tickerRef.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.75,
          ease: Power4.easeInOut,
        },
      )
        .fromTo(
          sphereRef.current,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 1,
            ease: Power4.easeInOut,
          },
          '-=0.25',
        )
        .fromTo(
          childSplit.lines,
          {
            transition: 'none',
            transformOrigin: '0% 0%',
            scaleY: 1,
            yPercent: 100,
          },
          {
            duration: 0.75,
            transformOrigin: '0% 0%',
            scaleY: 1,
            opacity: 1,
            y: 0,
            yPercent: 0,
            stagger: 0.075,
            ease: Power4.easeInOut,
            onComplete: () => {
              // @ts-ignore
              childSplit.revert();
            },
          },
          '-=0.75',
        )
        .fromTo(
          childSplit2.lines,
          {
            transition: 'none',
            transformOrigin: '0% 0%',
            scaleY: 1,
            yPercent: 100,
          },
          {
            duration: 0.75,
            transformOrigin: '0% 0%',
            scaleY: 1,
            opacity: 1,
            y: 0,
            yPercent: 0,
            stagger: 0.075,
            ease: Power4.easeInOut,
            onComplete: () => {
              // @ts-ignore
              childSplit2.revert();
            },
          },
          '-=0.5',
        )
        .fromTo(
          actionRef.current,
          {},
          {
            opacity: 1,
            duration: 0.5,
            ease: Power4.easeInOut,
          },
          '-=0.5',
        );

      return () => {
        tl.kill();
      };
    }, [preloaded, loaded]);

    return (
      <StyledBanner ref={ref}>
        <TickerTitle ref={tickerRef}>Super Rare Bears</TickerTitle>
        <div className="container">
          <div className="grid">
            <div className="col-xs-12 sphere" ref={sphereRef}>
              <DynamicMagicSphere />
            </div>
            <div className="col-xs-12 grid banner-description">
              <div className="col-xs-7 col-md-3 col-xl-2">
                <p className="text-super-small text-1" ref={textRef1}>
                  {subtitle}
                </p>
              </div>
              <div className="col-xs-12 grid">
                <div className="col-xs-10 col-md-4 col-xl-4 right-aligned">
                  <div
                    className="text-2"
                    ref={textRef2}
                    dangerouslySetInnerHTML={{ __html: md.render(description) }}
                  />
                </div>
                <div
                  className="col-xs-12 col-md-3 col-xl-2 cta"
                  ref={actionRef}
                >
                  <Button
                    as="a"
                    type="primary"
                    href="https://linktr.ee/srb_nft"
                    target="_blank"
                  >
                    Buy a bear
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledBanner>
    );
  },
);

Banner.displayName = 'Banner';

export default inject('uiStore')(observer(Banner));
