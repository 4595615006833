import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const Wrapper = styled.div`
  padding-top: ${({ theme }) => parseInt(theme.spacings.x10) + 4}px;
  padding-bottom: ${({ theme }) => theme.spacings.x5};
  position: relative;

  ${breakpoints.MD} {
    padding-top: ${({ theme }) => parseInt(theme.spacings.x20)}px;
  }

  ${breakpoints.LG} {
    padding-top: 220px;
  }
`;

const CenterMarker = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: none;
  width: 100%;
  height: 1px;
  //background-color: blue;
  z-index: 1000;
  transform: translateX(-50%);
  display: none;
`;

const BearPathSection = styled.section`
  border-radius: ${({ theme }) => theme.spacings.x6};
  background-color: ${({ theme }) => theme.colors.primary.second};
  color: ${({ theme }) => theme.colors.text.dark};
  overflow: hidden;
  position: relative;

  ${breakpoints.MD} {
    border-radius: 100px;
  }

  ${breakpoints.LG} {
    .title {
      padding-right: 35%;
      font-size: 140px;
      line-height: 120px;
    }
  }
`;

const BearpathGrid = styled.div`
  display: flex;
  flex-flow: row wrap;

  ${breakpoints.MD} {
    flex-flow: row nowrap;
  }
`;

const BearpathImage = styled.div`
  width: 100%;
  height: 474px;
  position: relative;
  mix-blend-mode: darken;
  transform: scaleX(-1);

  ${breakpoints.MD} {
    height: 720px;
    transform: none;
    width: 48.6%;
  }

  ${breakpoints.MDL} {
    width: 51.8%;
    height: 715px;
  }

  ${breakpoints.LG} {
    width: 52.6%;
    height: 720px;
  }

  .image-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    left: -45px;
    width: 374px;
    height: 534px;

    ${breakpoints.MD} {
      height: 720px;
      left: unset;
      right: 0;
      bottom: 0;
      top: 0;
    }

    ${breakpoints.LG} {
      width: 646px;
      height: 968px;
      top: unset;
      bottom: 0;
    }
  }
`;

const BearpathContent = styled.div`
  width: 100%;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacings.x7}
    ${({ theme }) => parseInt(theme.spacings.x2) + 8}px 110px;
  border-top: 2px solid ${({ theme }) => theme.colors.bg};
  position: relative;

  ${breakpoints.MD} {
    width: 80%;
    border-top: none;
    border-left: 2px solid ${({ theme }) => theme.colors.bg};
    padding: ${({ theme }) => parseInt(theme.spacings.x10) + 9}px
      ${({ theme }) => parseInt(theme.spacings.x2) + 8}px 110px
      ${({ theme }) => theme.spacings.x7};
  }
`;

const BearpathList = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  list-style-type: none;
  margin: 0;
  padding: ${({ theme }) => parseInt(theme.spacings.x4) + 3}px 0;

  ${breakpoints.MD} {
    padding: ${({ theme }) => parseInt(theme.spacings.x8) + 1}px 0;
  }
`;

const BearpathItem = styled.li`
  width: auto;
  flex-grow: 1;

  & + & {
    margin-left: 77px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -43px;
      top: 5px;
      width: 13px;
      height: 22px;
      background-image: url('/arrow-right-dark.svg');
    }
  }
`;

const BearpathLabel = styled.p`
  white-space: nowrap;
  font-size: 24px;
  line-height: 34px;
  padding-bottom: ${({ theme }) => theme.spacings.x2};

  ${breakpoints.MD} {
    padding-bottom: ${({ theme }) => theme.spacings.x4};
  }
`;

interface BearpathProgressProps {
  dashed: boolean;
  active: boolean;
}

const BearpathProgress = styled.div<BearpathProgressProps>`
  border: 2px solid ${({ theme }) => theme.colors.bg};
  padding: 2px;
  border-radius: 100px;
  display: inline-block;
  font-size: 0;
  height: 34px;

  & > span {
    display: inline-block;
    width: 71px;
    height: 26px;
    border: 2px solid ${({ theme }) => theme.colors.bg};
    border-radius: 100px;
    overflow: hidden;
    position: relative;

    span {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) => theme.colors.primary.first};
    }
  }

  ${({ dashed }) =>
    dashed &&
    css`
      & > span {
        border-style: dashed;
      }
    `}

  ${({ active, theme }) =>
    !!active &&
    css`
      & > span {
        span {
          border-right: 2px solid ${theme.colors.bg};
        }
      }
    `}
`;

const BearpathTicker = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${({ theme }) => theme.spacings.x2};
  display: flex;
  flex-flow: row nowrap;
  z-index: 6;

  && {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: 0.002em;
    bottom: 38px;
  }

  span {
    width: auto;
    white-space: nowrap;

    & + span {
      position: relative;
      margin-left: ${({ theme }) => theme.spacings.x5};

      ${breakpoints.MD} {
        margin-left: ${({ theme }) => parseInt(theme.spacings.x5) - 2}px;
      }

      ${breakpoints.MDL} {
        margin-left: ${({ theme }) => theme.spacings.x5};
      }

      ${breakpoints.LG} {
        margin-left: ${({ theme }) => theme.spacings.x5};
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -24px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: currentColor;

        ${breakpoints.MD} {
          width: 6px;
          height: 6px;
          left: -22px;
        }

        ${breakpoints.MDL} {
          left: -24px;
          width: 8px;
          height: 8px;
        }

        ${breakpoints.LG} {
          left: -24px;
          width: 8px;
          height: 8px;
        }
      }
    }
  }
`;

export {
  BearPathSection,
  BearpathGrid,
  BearpathImage,
  BearpathContent,
  BearpathList,
  BearpathItem,
  BearpathLabel,
  BearpathProgress,
  BearpathTicker,
  Wrapper,
  CenterMarker,
};
