import { cloneElement, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { gsap, Power0, Power4 } from 'gsap';
import Image from 'next/image';
import {
  AbominationsBear,
  AbominationsGrid,
  AbominationsLayer,
  AbominationsSection,
  AbominationsTicker,
  Helper,
} from '@/scenes/Home/Abominations/styles';
import Button from '@/components/UI/Button';
import Abominator1 from '@/assets/Abominations/Abominator1.svg';
import Abominator2 from '@/assets/Abominations/Abominator2.svg';
import Forest from '@/assets/forest.webp';
import Fog from '@/assets/fog.webp';
import useMediaQuery from '@/hooks/useMediaQuery';
import { SplitText } from '@/utils/gsap/SplitText';
import uniq from 'lodash.uniq';
import { Remarkable } from 'remarkable';
const md = new Remarkable();

interface Props {
  subtitle: string;
  description: string;
}

const Abominations = ({ subtitle, description }: Props) => {
  const [loaded, setLoaded] = useState(false);
  const tickerWrapperRef = useRef(null);
  const tickerTextRef = useRef(null);
  const xRayRef = useRef(null);
  const sectionRef = useRef(null);
  const [doubletapped, setDoubletapped] = useState(false);

  const tickerWrapperRef2 = useRef(null);
  const tickerTextRef2 = useRef(null);

  const isSmallDevice = useMediaQuery('(max-width: 991px)');

  const animatedItems = useRef([]);

  const actionsRef = useRef([]);

  /* Based on this http://jsfiddle.net/brettwp/J4djY/*/
  const detectDoubleTapClosure = () => {
    let lastTap = 0;
    let timeout;
    return function detectDoubleTap(event) {
      const curTime = new Date().getTime();
      const tapLen = curTime - lastTap;
      if (tapLen < 500 && tapLen > 0) {
        setDoubletapped(true);
        document.body.style.overflow = 'hidden';
        sectionRef.current.addEventListener('touchmove', xRay);

        event.preventDefault();
      } else {
        timeout = setTimeout(() => {
          clearTimeout(timeout);
        }, 500);
      }
      lastTap = curTime;
    };
  };

  const onTouchEnd = () => {
    document.body.style.overflow = 'visible';
    sectionRef.current.removeEventListener('touchmove', xRay);
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;

    uniq([
      ...actionsRef.current.filter((i) => i),
      tickerWrapperRef.current,
      tickerWrapperRef2.current,
    ]).forEach((element) => {
      let tl1 = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: 'top 92%',
          toggleActions: 'play none none none',
        },
      });
      tl1.fromTo(
        element,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.75,
          delay: 0.2,
          ease: Power4.easeInOut,
        },
      );
    });

    gsap.utils
      .toArray([
        ...uniq(animatedItems.current.filter((i) => i)),
        '.cta__text p',
      ])
      .forEach((element) => {
        const childSplit = new SplitText(element, {
          type: 'lines',
          linesClass: 'animatedLineChild',
        });
        const parentSplit = new SplitText(element, {
          type: 'lines',
          linesClass: 'animatedLineParent',
        });

        let tl2 = gsap.timeline({
          scrollTrigger: {
            // @ts-ignore
            trigger: element,
            start: 'top 92%',
            toggleActions: 'play none none none',
          },
        });
        tl2
          .fromTo(
            childSplit.lines,
            {
              transition: 'none',
              transformOrigin: '0% 0%',
              scaleY: 1,
              yPercent: 100,
            },
            {
              duration: 0.75,
              transformOrigin: '0% 0%',
              delay: 0.2,
              scaleY: 1,
              opacity: 1,
              y: 0,
              yPercent: 0,
              stagger: 0.075,
              ease: Power4.easeInOut,
              onComplete: () => {
                // @ts-ignore
                // childSplit.revert();
              },
            },
          )
          .set(parentSplit.lines, { overflow: 'visible' }, 0.2);
      });

    const textWidth = tickerTextRef.current.offsetWidth + 40;

    const tl = gsap.timeline({ repeat: -1 });

    tl.to([tickerWrapperRef.current, tickerWrapperRef2.current], {
      duration: 3,
      x: `-=${textWidth}`,
      ease: Power0.easeNone,
      onComplete: () => {
        const style = window.getComputedStyle(tickerWrapperRef.current);
        const matrix = new WebKitCSSMatrix(style.webkitTransform);

        if (matrix.m41 <= -textWidth) {
          gsap.set([tickerWrapperRef.current, tickerWrapperRef2.current], {
            x: 0,
          });
        }

        tl.invalidate();
      },
    });

    /* Regex test to determine if user is on mobile */
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      document.body.addEventListener('touchstart', detectDoubleTapClosure());
      document.body.addEventListener('touchend', onTouchEnd);
    }

    if (!isMobile) {
      sectionRef.current.addEventListener('mousemove', xRay);
      sectionRef.current.addEventListener('mouseenter', xRayIn);
      sectionRef.current.addEventListener('mouseleave', xRayOut);
    }

    return () => {
      tl.kill();

      if (!sectionRef.current) return;
      if (!isMobile) {
        sectionRef.current.removeEventListener('mousemove', xRay);
        sectionRef.current.removeEventListener('mouseleave', xRayOut);
        sectionRef.current.removeEventListener('mouseenter', xRayIn);
      }
    };
  }, [loaded]);

  const getPositions = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();

    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const resX = Math.floor(x) >= 0 ? Math.floor(x) : 0;
    const resY = Math.floor(y) >= 0 ? Math.floor(y) : 0;

    return [resX, resY];
  };

  const xRayIn = (e) => {
    const [x, y] = getPositions(e);

    gsap.set(xRayRef.current, {
      'clip-path': `circle(0px at ${x}px ${y}px)`,
    });
  };

  const xRayOut = (e) => {
    const [x, y] = getPositions(e);

    gsap.killTweensOf(xRayRef.current);

    gsap.to(xRayRef.current, 0.15, {
      ease: Power0.easeNone,
      'clip-path': `circle(0px at ${x}px ${y}px)`,
    });
  };

  const xRay = (e) => {
    if (e.type !== 'touchmove') {
      const rect = e.currentTarget.getBoundingClientRect();

      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const width = isSmallDevice ? 130 : 150;

      gsap.to(xRayRef.current, 0.3, {
        'clip-path': `circle(${width}px at ${x}px ${y}px)`,
      });
    } else {
      const x = e.layerX;
      const y = e.layerY;

      const width = isSmallDevice ? 130 : 150;

      gsap.to(xRayRef.current, 0.3, {
        'clip-path': `circle(${width}px at ${x}px ${y}px)`,
      });
    }
  };

  return (
    <AbominationsSection ref={sectionRef}>
      {!doubletapped && (
        <Helper>
          <svg
            width="33"
            height="55"
            viewBox="0 0 33 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2224_53613)">
              <path
                d="M8.95616 8.59968C6.1033 8.59968 3.74419 1.89269 4.77287 0.768007C5.8427 -0.370397 11.7953 3.16826 11.5621 6.11713C11.4661 7.43384 10.1494 8.62711 8.95616 8.59968Z"
                fill="white"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.70763 23.7692C6.25377 25.7305 0.753769 24.1532 0.548033 22.9051C0.356013 21.6296 5.67771 19.2293 7.44703 20.7792C8.25626 21.4787 8.33856 22.9463 7.70763 23.7692Z"
                fill="white"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.8347 26.0325C8.73622 26.2108 6.73373 25.1958 5.81478 23.4539C4.5255 21.0125 6.04794 18.5437 6.29482 18.1734C7.69383 15.9788 10.0941 15.5948 10.4781 15.5399C6.98061 13.2769 5.60904 9.42274 6.80231 6.5973C7.48809 4.99256 9.17512 3.36039 11.4108 3.18209C13.9619 2.99007 16.4719 4.77311 17.3085 7.51625C17.9943 7.36538 19.6676 7.10478 21.5467 7.83171C24.0018 8.76438 25.1676 10.6846 25.4694 11.2469C25.922 11.3155 26.5666 11.4389 27.2799 11.7681C29.639 12.8242 30.7225 14.9502 31.3123 16.1023C33.7125 20.8342 31.6552 28.035 31.518 28.5013C28.1851 40.2419 25.8809 46.5923 23.4806 51.914C23.0006 52.9701 22.0679 53.7245 20.9295 53.9576C18.6527 54.424 14.8534 54.7257 10.8622 52.8741C8.53049 51.7906 6.76116 51.0911 5.81478 49.1297C5.03298 47.5113 5.01383 46.0001 5.34844 44.9876C6.43198 41.7095 7.48809 38.308 8.50305 34.7694C9.33971 31.7931 10.1215 28.8716 10.8347 26.0325Z"
                fill="black"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.359 12.9064C11.8777 13.3727 10.2455 12.2069 9.54599 10.9176C8.79163 9.5323 8.79163 7.37893 10.1221 6.43255C11.5896 5.36272 14.1408 6.19938 15.046 8.00985C15.9649 9.80661 15.0871 12.3714 13.359 12.9064Z"
                fill="white"
                stroke="#0B0A0A"
                strokeWidth="0.152958"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.29766 23.0424C7.31013 22.0548 7.68045 20.1895 8.61312 19.1745C9.35377 18.3516 10.8076 17.7069 12.0283 18.3516C12.865 18.8042 13.345 19.7094 13.3725 20.5735C13.4273 22.3566 11.5483 23.2481 11.4523 23.2892C10.6293 23.6459 9.16175 23.9064 8.29766 23.0424Z"
                fill="white"
                stroke="#0B0A0A"
                strokeWidth="0.152958"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.8161 19.1882C15.5817 19.7643 14.9096 21.0124 15.129 22.1097C15.3896 23.3441 16.7201 24.0299 17.6939 24.0573C18.7774 24.0847 19.0654 23.3167 20.4919 23.2892C21.2737 23.2755 21.8635 23.495 22.3984 23.7007C24.0305 24.3179 24.0991 25.0448 25.3472 25.3603C25.649 25.4289 27.1577 25.8129 27.9807 24.894C28.543 24.2768 28.6116 23.2755 28.2275 22.3291"
                fill="black"
              />
              <path
                d="M16.8161 19.1882C15.5817 19.7643 14.9096 21.0124 15.129 22.1097C15.3896 23.3441 16.7201 24.0299 17.6939 24.0573C18.7774 24.0847 19.0654 23.3167 20.4919 23.2892C21.2737 23.2755 21.8635 23.495 22.3984 23.7007C24.0305 24.3179 24.0991 25.0448 25.3472 25.3603C25.649 25.4289 27.1577 25.8129 27.9807 24.894C28.543 24.2768 28.6116 23.2755 28.2275 22.3291"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.8161 19.1882C15.5817 19.7643 14.9096 21.0124 15.129 22.1097C15.3896 23.3441 16.7201 24.0299 17.6939 24.0573C18.7774 24.0847 19.0654 23.3167 20.4919 23.2892C21.2737 23.2755 21.8635 23.495 22.3984 23.7007C24.0305 24.3179 24.0991 25.0448 25.3472 25.3603C25.649 25.4289 27.1577 25.8129 27.9807 24.894C28.543 24.2768 28.6116 23.2755 28.2275 22.3291"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.4584 12.3853C16.1978 12.591 15.128 13.5237 14.9086 15.1147C14.7303 16.4314 15.224 17.5286 15.6355 18.1184C16.8562 19.8603 19.6816 20.4775 22.5619 19.4626"
                fill="black"
              />
              <path
                d="M16.4584 12.3853C16.1978 12.591 15.128 13.5237 14.9086 15.1147C14.7303 16.4314 15.224 17.5286 15.6355 18.1184C16.8562 19.8603 19.6816 20.4775 22.5619 19.4626"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.4584 12.3853C16.1978 12.591 15.128 13.5237 14.9086 15.1147C14.7303 16.4314 15.224 17.5286 15.6355 18.1184C16.8562 19.8603 19.6816 20.4775 22.5619 19.4626"
                stroke="white"
                strokeWidth="0.152958"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M23.0286 15.1284C22.7543 15.4987 22.2605 16.2531 22.2468 17.2544C22.2057 19.1608 23.9064 20.9302 25.8266 21.2731C27.6645 21.6159 29.1047 20.5324 29.4064 20.2992"
                fill="black"
              />
              <path
                d="M23.0286 15.1284C22.7543 15.4987 22.2605 16.2531 22.2468 17.2544C22.2057 19.1608 23.9064 20.9302 25.8266 21.2731C27.6645 21.6159 29.1047 20.5324 29.4064 20.2992"
                stroke="white"
                strokeWidth="1.09726"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M23.0286 15.1284C22.7543 15.4987 22.2605 16.2531 22.2468 17.2544C22.2057 19.1608 23.9064 20.9302 25.8266 21.2731C27.6645 21.6159 29.1047 20.5324 29.4064 20.2992"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_2224_53613">
                <rect width="33" height="54.8628" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span className="text">Double tap to drag</span>
        </Helper>
      )}
      <AbominationsLayer ref={xRayRef} top>
        <div className="container">
          <AbominationsGrid>
            <h2 className="title" ref={(r) => animatedItems.current.push(r)}>
              Abominations
            </h2>
            <p
              className="description text-super-small"
              ref={(r) => animatedItems.current.push(r)}
            >
              {subtitle}
            </p>
            <div className="cta">
              <div
                className="cta__text"
                dangerouslySetInnerHTML={{ __html: md.render(description) }}
              ></div>
              <Button
                as="a"
                href="https://linktr.ee/abominator_nft"
                target="_blank"
                type="primary"
                className="cta__action"
                // @ts-ignore
                ref={(r) => actionsRef.current.push(r)}
              >
                Buy Abominator
              </Button>
            </div>
          </AbominationsGrid>
        </div>
        <AbominationsBear>
          {cloneElement(<Abominator1 />, { viewBox: '0 0 594 594' })}
        </AbominationsBear>
        <AbominationsTicker ref={tickerWrapperRef} className="subtitle">
          <span ref={tickerTextRef}>Coming Soon</span>
          {loaded &&
            [...Array(30)].map((e, i) => {
              return <span key={i.toString()}>Coming Soon</span>;
            })}
        </AbominationsTicker>
      </AbominationsLayer>
      {loaded && (
        <AbominationsLayer>
          <div className="container">
            <AbominationsGrid>
              <h2 className="title" ref={(r) => animatedItems.current.push(r)}>
                Abominations
              </h2>
              <p
                className="description text-super-small"
                ref={(r) => animatedItems.current.push(r)}
              >
                {subtitle}
              </p>
              <div className="cta">
                <div
                  className="cta__text"
                  dangerouslySetInnerHTML={{ __html: md.render(description) }}
                ></div>
                <Button
                  as="a"
                  href="https://linktr.ee/abominator_nft"
                  target="_blank"
                  type="primary"
                  className="cta__action"
                  // @ts-ignore
                  ref={(r) => actionsRef.current.push(r)}
                >
                  Buy Abominator
                </Button>
              </div>
            </AbominationsGrid>
          </div>
          <div className="bg-pattern">
            <Image src={Forest} alt="forest" />
          </div>
          <AbominationsBear>
            {cloneElement(<Abominator2 />, { viewBox: '0 0 594 594' })}
          </AbominationsBear>
          <div className="bg-pattern bg-pattern--fog">
            <Image src={Fog} alt="forest" />
          </div>
          {/*<Fog className="bg-pattern bg-pattern--fog" />*/}
          <AbominationsTicker ref={tickerWrapperRef2} className="subtitle">
            <span ref={tickerTextRef2}>Coming Soon</span>
            {[...Array(30)].map((e, i) => {
              return <span key={i.toString()}>Coming Soon</span>;
            })}
          </AbominationsTicker>
        </AbominationsLayer>
      )}
    </AbominationsSection>
  );
};

export default Abominations;
