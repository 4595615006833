import { Fragment, useEffect, useRef, useState } from 'react';
import { gsap, Power0, Power4 } from 'gsap';
import Image from 'next/image';
import {
  BearpathContent,
  BearpathGrid,
  BearpathImage,
  BearpathItem,
  BearpathLabel,
  BearpathList,
  BearpathProgress,
  BearpathTicker,
  BearPathSection,
  Wrapper,
  CenterMarker,
} from '@/scenes/Home/Bearpath/styles';
import bearpathImage from '@/assets/bearpath.png';
import useMediaQuery from '@/hooks/useMediaQuery';
import { breakpointsBase } from '@/styles/breakpoints';
import { SplitText } from '@/utils/gsap/SplitText';
import uniq from 'lodash.uniq';

interface BearpathItem {
  label: string;
  progress: number;
}

interface Props {
  title: string;
  bearpathItem: { title: string; percentage: number }[];
}

const Bearpath = ({ title, bearpathItem }: Props) => {
  const bearpathItems = bearpathItem.map((item) => ({
    label: item.title,
    progress: item.percentage,
  }));

  const pathItemsRef = useRef([]);

  const tickerRef = useRef(null);
  const tickerTextRef = useRef(null);
  const imageRef = useRef(null);
  const sectionRef = useRef(null);
  const bearpathContentRef = useRef(null);
  const bearpathList = useRef(null);

  const [loaded, setLoaded] = useState(false);

  const isMD = useMediaQuery(breakpointsBase.MD);

  const animatedTextItems = useRef([]);
  const fadeItemsRef = useRef([]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;

    const textWidth = tickerTextRef.current.offsetWidth + 40;

    const bearpathTl = gsap.timeline({
      scrollTrigger: {
        trigger: bearpathList.current,
        start: 'top 92%',
        // markers: true,
      },
    });

    bearpathTl.fromTo(
      bearpathList.current,
      {
        xPercent: 100,
      },
      {
        xPercent: 0,
        duration: 1,
        ease: Power4.easeInOut,
      },
    );

    [...uniq(fadeItemsRef.current.filter((i) => i)), tickerRef.current].forEach(
      (element) => {
        let tl1 = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: 'top 92%',
            // markers: true,
          },
        });
        tl1.fromTo(
          element,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.75,
            ease: Power4.easeInOut,
          },
        );
      },
    );

    uniq(animatedTextItems.current.filter((i) => i)).forEach((element) => {
      const childSplit = new SplitText(element, {
        type: 'lines',
        linesClass: 'animatedLineChild',
      });
      const parentSplit = new SplitText(element, {
        type: 'lines',
        linesClass: 'animatedLineParent',
      });

      let tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: 'top 92%',
          // markers: true,
        },
      });
      tl2
        .fromTo(
          childSplit.lines,
          {
            transition: 'none',
            transformOrigin: '0% 0%',
            scaleY: 1,
            yPercent: 100,
          },
          {
            duration: 0.75,
            transformOrigin: '0% 0%',
            delay: 0.2,
            scaleY: 1,
            opacity: 1,
            y: 0,
            yPercent: 0,
            stagger: 0.075,
            ease: Power4.easeInOut,
            onComplete: () => {
              // @ts-ignore
              childSplit.revert();
            },
          },
        )
        .set(parentSplit.lines, { overflow: 'visible' }, 0.2);
    });

    const tl = gsap.timeline({ repeat: -1 });

    tl.to(tickerRef.current, {
      duration: 3,
      x: `-=${textWidth}`,
      ease: Power0.easeNone,
      onComplete: () => {
        const style = window.getComputedStyle(tickerRef.current);
        const matrix = new WebKitCSSMatrix(style.webkitTransform);

        if (matrix.m41 <= -textWidth) {
          gsap.set(tickerRef.current, {
            x: 0,
          });
        }

        tl.invalidate();
      },
    });

    let x = `-=${bearpathList.current.scrollWidth - window.innerWidth + 48}`;
    let start = 'bottom bottom';

    if (isMD) {
      x = `-=${
        bearpathList.current.scrollWidth -
        window.innerWidth +
        imageRef.current.offsetWidth +
        80
      }`;
      start = 'center center';
    }

    setTimeout(() => {
      gsap.to(bearpathList.current, {
        x,
        ease: 'none',
        scrollTrigger: {
          trigger: bearpathContentRef.current,
          start,
          pin: sectionRef.current,
          anticipatePin: 1,
          scrub: true,
          refreshPriority: 1,
          // markers: true,
        },
      });
    }, 1000);

    return () => {
      tl.kill();
      bearpathTl.kill();
    };
  }, [loaded]);

  return (
    <Wrapper>
      <BearPathSection ref={sectionRef}>
        <CenterMarker />
        <BearpathGrid>
          <BearpathImage ref={imageRef}>
            <div
              className="image-wrapper"
              ref={(r) => fadeItemsRef.current.push(r)}
            >
              <Image
                priority
                src={bearpathImage}
                layout="fill"
                alt="2022 Bearpath"
              />
            </div>
          </BearpathImage>
          <BearpathContent ref={bearpathContentRef}>
            <h2
              className="title"
              ref={(r) => animatedTextItems.current.push(r)}
            >
              {title.split('\n').map((i, index) => (
                <Fragment key={i}>
                  {index !== 0 && <br />}
                  {i}
                </Fragment>
              ))}
            </h2>
            <BearpathList ref={bearpathList}>
              {bearpathItems.map((item) => (
                <BearpathItem
                  key={item.label}
                  ref={(r) => pathItemsRef.current.push(r)}
                >
                  <BearpathLabel>{item.label}</BearpathLabel>
                  <BearpathProgress
                    active={item.progress}
                    dashed={item.label.includes('Research')}
                  >
                    <span>
                      <span
                        style={{
                          width: item.progress
                            ? `calc(${item.progress}% + 2px)`
                            : 0,
                        }}
                      />
                    </span>
                  </BearpathProgress>
                </BearpathItem>
              ))}
            </BearpathList>
            <BearpathTicker ref={tickerRef} className="subtitle">
              <span ref={tickerTextRef}>Work in Progress</span>
              {[...Array(20)].map((e, i) => {
                return <span key={i.toString()}>Work in Progress</span>;
              })}
            </BearpathTicker>
          </BearpathContent>
        </BearpathGrid>
      </BearPathSection>
    </Wrapper>
  );
};

export default Bearpath;
