import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const StyledSliderSection = styled.section`
  padding-top: ${({ theme }) => parseInt(theme.spacings.x10) - 5}px;

  ${breakpoints.MD} {
    padding-top: ${({ theme }) => parseInt(theme.spacings.x20) - 2}px;
  }

  ${breakpoints.LG} {
    padding-top: ${({ theme }) => parseInt(theme.spacings.x20) - 7}px;
  }
`;

interface SliderHeaderProps {
  rtl?: boolean;
}

const SliderHeader = styled.div<SliderHeaderProps>`
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: space-between;

  .title {
    position: relative;
    top: 11px;
    padding-right: 10%;

    ${breakpoints.MD} {
      top: 15px;
      padding-right: 25%;
    }

    ${breakpoints.LG} {
      padding-right: 30%;
      top: 20px;
    }
  }

  ${({ rtl }) =>
    rtl &&
    css`
      .title {
        order: 2;
        padding-right: 0;
        padding-left: 10%;

        ${breakpoints.MD} {
          padding-left: 25%;
        }

        ${breakpoints.LG} {
          padding-left: 30%;
        }
      }
    `}
`;

const SliderCounter = styled.div`
  color: ${({ theme }) => theme.colors.text.grey};
  line-height: ${({ theme }) => theme.fonts.lineHeights.large};
  min-width: 30px;
  text-align: right;

  ${breakpoints.MD} {
    font-size: ${({ theme }) => theme.fonts.sizes.tablet.large};
  }

  ${breakpoints.XXLG} {
    position: relative;
    bottom: 12px;
  }
`;

const SliderContent = styled.div<{ blurred: boolean }>`
  padding: ${({ theme }) => `${theme.spacings.x7} 0`};
  position: relative;

  ${breakpoints.MD} {
    padding-bottom: ${({ theme }) => parseInt(theme.spacings.x6) + 4}px;
  }

  ${breakpoints.MDL} {
    padding-bottom: ${({ theme }) => parseInt(theme.spacings.x6) + 6}px;
  }

  ${breakpoints.LG} {
    padding-bottom: ${({ theme }) => parseInt(theme.spacings.x6) + 6}px;
  }

  ${breakpoints.XXLG} {
    padding: ${({ theme }) =>
      `${parseInt(theme.spacings.x6) - 4}px 0 ${
        parseInt(theme.spacings.x7) + 1
      }px`};
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 25%;
    height: 85%;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    will-change: opacity;
    transform: translateY(-50%);

    ${breakpoints.XXLG} {
      height: 90%;
    }
  }

  &:before {
    left: -18px;
    background: linear-gradient(90deg, rgba(9, 9, 9, 1) 0%, transparent 100%);

    ${breakpoints.MD} {
      left: -40px;
    }

    ${breakpoints.MDL} {
      left: -30px;
    }

    ${breakpoints.LG} {
      left: -20px;
    }

    ${breakpoints.XXLG} {
      left: -20px;
    }
  }

  &:after {
    right: -18px;
    background: linear-gradient(90deg, transparent 0%, rgba(9, 9, 9, 1) 100%);

    ${breakpoints.MD} {
      right: -40px;
    }

    ${breakpoints.MDL} {
      right: -62px;
    }

    ${breakpoints.LG} {
      right: -62px;
    }

    ${breakpoints.XXLG} {
      right: -120px;
    }
  }

  ${({ blurred }) =>
    blurred &&
    css`
      &:before,
      &:after {
        opacity: 1;
      }
    `}
`;

const SliderFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: space-between;
`;

const SliderAction = styled.div``;

const SliderArrows = styled.div``;

export {
  StyledSliderSection,
  SliderHeader,
  SliderCounter,
  SliderContent,
  SliderFooter,
  SliderAction,
  SliderArrows,
};
