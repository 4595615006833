import Slider from 'react-slick';
import {
  FeaturedBearsSection,
  FeaturedBearsSlider,
  SliderCutter,
} from './styles';
import SliderSection from '@/scenes/common/SliderSection';
import Button from '@/components/UI/Button';
import { BearCard } from '@/components/Bear';
import { FC, useEffect, useRef, useState } from 'react';
import useMediaQuery from '@/hooks/useMediaQuery';
import { breakpointsBase } from '@/styles/breakpoints';
import { IBearsStore } from '@/stores/Bears';
import { inject, observer } from 'mobx-react';
import { values } from 'mobx';
import { gsap, Power4 } from 'gsap';

interface FeaturedBearsProps {
  bearsStore: IBearsStore;
  title: string;
}

const FeaturedBears: FC<any> = ({ bearsStore, title }: FeaturedBearsProps) => {
  const [loaded, setLoaded] = useState(false);
  const sliderWrapperRef = useRef(null);

  const bears = values(bearsStore.featuredBears);

  const isMD = useMediaQuery(breakpointsBase.MD);
  const isLG = useMediaQuery(breakpointsBase.LG);

  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(2);

  useEffect(() => {
    setLoaded(true);

    if (isMD) {
      setCurrentSlide(3);
    }
    if (isLG) {
      setCurrentSlide(4);
    }
  }, []);

  useEffect(() => {
    if (!loaded) return;

    const tl = gsap.timeline({
      scrollTrigger: {
        // @ts-ignore
        trigger: sliderWrapperRef.current,
        start: 'top bottom+=100px',
        // markers: true,
      },
    });

    tl.fromTo(
      sliderWrapperRef.current,
      {
        transition: 'none',
        transformOrigin: '0% 0%',
        xPercent: 100,
      },
      {
        duration: 2.5,
        xPercent: 0,
        ease: Power4.easeInOut,
      },
    ).fromTo(
      '.featured-bears-slider .slick-active',
      {
        transition: 'none',
        transformOrigin: '100% 50%',
        scaleX: 0.8,
        scaleY: 0.9,
        xPercent: 100,
        opacity: 0,
      },
      {
        duration: 2,
        transformOrigin: '100% 50%',
        scaleY: 1,
        scaleX: 1,
        delay: 0.2,
        xPercent: 0,
        opacity: 1,
        stagger: 0.25,
        ease: Power4.easeInOut,
      },
      0,
    );

    return () => {
      tl.kill();
    };
  }, [loaded]);

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    swipe: false,
    afterChange: (current) => {
      if (current === 0) {
        if (isLG) {
          return setCurrentSlide(4);
        }
        if (isMD) {
          return setCurrentSlide(3);
        }
        return setCurrentSlide(2);
      }

      if (isLG && current <= bears.length - 4) {
        return setCurrentSlide(current + 4);
      }

      if (isMD && !isLG && current <= bears.length - 3) {
        return setCurrentSlide(current + 3);
      }

      if (!isMD && current <= bears.length - 2) {
        return setCurrentSlide(current + 2);
      }

      if (isLG && current > bears.length - 4) {
        return setCurrentSlide(current - (bears.length - 4));
      }
      if (isMD && !isLG && current > bears.length - 3) {
        return setCurrentSlide(current - (bears.length - 3));
      }
      if (!isMD && current > bears.length - 2) {
        return setCurrentSlide(current - (bears.length - 2));
      }
    },
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          swipe: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          swipe: true,
        },
      },
    ],
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  if (!bears.length) return null;

  return (
    <FeaturedBearsSection id="featured-bears">
      <div className="container">
        <SliderSection
          title={title}
          counter={{ current: currentSlide, total: bears.length }}
          action={
            <Button colored as="a" href="/gallery">
              Browse Gallery
            </Button>
          }
          onPrevSlide={prevSlide}
          onNextSlide={nextSlide}
        >
          <SliderCutter ref={sliderWrapperRef}>
            <FeaturedBearsSlider className="featured-bears-slider">
              <Slider ref={sliderRef} {...sliderSettings}>
                {bears.map((bear) => (
                  <BearCard key={bear.identifier} bear={bear} />
                ))}
              </Slider>
            </FeaturedBearsSlider>
          </SliderCutter>
        </SliderSection>
      </div>
    </FeaturedBearsSection>
  );
};

export default inject('bearsStore')(observer(FeaturedBears));
